<template>
  <div class="space-y-10">
    <asom-card>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field label="Date">
          <asom-input-date-range v-model="filters.dateRange" />
        </asom-form-field>
        <div></div>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="loadData(true)" />
      </template>
    </asom-card>
    <div class="w-full text-center" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-client-table
      :columns="columns.fields"
      :data="tableData"
      :sortableColumns="columns.sortable"
    >
      <template v-slot:planDate="scopedSlots">
        <div class="space-x-2 flex flex-row items-center">
          <span class="flex-1">{{ scopedSlots.rowData.planDate }}</span>
          <asom-tooltip
            v-if="get(scopedSlots.rowData, 'isBlockedLeave')"
            tooltip-text="Indicated Leave"
          >
            <template #toggleText>
              <asom-badge variant="primary">B</asom-badge>
            </template>
          </asom-tooltip>
          <asom-tooltip
            v-if="get(scopedSlots.rowData, 'otUnavailable')"
            tooltip-text="Unavailability for OT"
          >
            <template #toggleText>
              <asom-badge variant="error"
                ><span class="line-through">V</span></asom-badge
              >
            </template>
          </asom-tooltip>
          <asom-tooltip
            v-if="get(scopedSlots.rowData, 'otVolunteer')"
            tooltip-text="Volunteer for OT"
          >
            <template #toggleText>
              <asom-badge variant="success">V</asom-badge>
            </template>
          </asom-tooltip>
        </div>
      </template>
      <template v-slot:header_planDate>Plan Date</template>
      <template v-slot:header_planStart>Plan Start</template>
      <template v-slot:header_planEnd>Plan End</template>
      <template v-slot:station="scopedSlots">
        {{ station(get(scopedSlots.rowData, "station")) }}
      </template>
      <template v-slot:day="scopedSlots">
        {{ setDay(get(scopedSlots.rowData, "planDate")) }}
      </template>
      <template v-slot:action="scopedSlots">
        <div class="flex flex-col gap-1 w-48">
          <asom-button
            v-if="
              !get(scopedSlots.rowData, 'isBlockedLeave') &&
                !isNonActiveShift(scopedSlots.rowData)
            "
            outline
            text="Indicate Leave"
            variant="primary"
            @click="
              $router.push({
                name: 'Block Leave',
                params: { rosterId: scopedSlots.rowData.rosterId },
                query: {
                  startDate: scopedSlots.rowData.planStart,
                  endDate: scopedSlots.rowData.planEnd,
                },
              })
            "
          />
          <asom-button
            v-if="
              !get(scopedSlots.rowData, 'otUnavailable') &&
                isNonActiveShift(scopedSlots.rowData)
            "
            outline
            text="Mark OT Unavailable"
            variant="error"
            @click="
              $router.push({
                name: 'Mark_OT_Unavail',
                params: { rosterId: scopedSlots.rowData.rosterId },
                query: { date: scopedSlots.rowData.planDate },
              })
            "
          />
          <asom-button
            v-if="
              !get(scopedSlots.rowData, 'otVolunteer') &&
                isNonActiveShift(scopedSlots.rowData)
            "
            outline
            text="Volunteer for OT"
            variant="success"
            @click="
              $router.push({
                name: 'OT_Volunteers',
                params: { rosterId: scopedSlots.rowData.rosterId },
                query: { date: scopedSlots.rowData.planDate },
              })
            "
          />
          <asom-button
            v-if="
              get(scopedSlots.rowData, 'otVolunteer') &&
                isNonActiveShift(scopedSlots.rowData)
            "
            outline
            size="sm"
            text="Cancel OT Volunteering"
            variant="error"
            @click="onCancelShiftType('OTV', scopedSlots.rowData)"
          />
          <asom-button
            v-if="
              get(scopedSlots.rowData, 'otUnavailable') &&
                isNonActiveShift(scopedSlots.rowData)
            "
            outline
            size="sm"
            text="Cancel OT Unavailability"
            variant="error"
            @click="onCancelShiftType('OTU', scopedSlots.rowData)"
          />
          <asom-button
            v-if="get(scopedSlots.rowData, 'isBlockedLeave')"
            outline
            size="sm"
            text="Cancel Indicated Leave"
            variant="error"
            @click="onCancelShiftType('BL', scopedSlots.rowData)"
          />
        </div>
      </template>
    </asom-client-table>
    <asom-modal
      v-model="showConfirmCancelShiftChange"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <p class="pt-4">{{ cancellationShiftType.message }}</p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="cancelShiftTypeConfirm(cancellationShiftType.type)"
          :disabled="isSubmittingCancelShiftChange"
          :loading="isSubmittingCancelShiftChange"
          text="Confirm"
        />
        <asom-button
          @click="toggleCancelShiftChangeModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import {
  getRosterList,
  removeOTVolunteer,
  removeBlockedLeave,
  removeOTUnavailability,
} from "@/services/manpower.service";
import { parseDateTime, toMoment } from "@/helpers/dateTimeHelpers";
import { setStorage, getStorage } from "@/helpers/sessionStorage";
import { mapGetters } from "vuex";
import router from "@/router/index"

export default {
  name: "Rosters",
  data() {
    return {
      tableData: [],
      filters: {
        dateRange: {
          start: moment().toDate(),
          end: moment()
            .add(4, "weeks")
            .toDate(),
        },
      },
      skip: 0,
      isLoading: false,
      error: null,
      selectedRoster: null,
      showConfirmCancelShiftChange: false,
      isSubmittingCancelShiftChange: false,
      cancellationShiftType: {
        message: "",
        type: null,
      },
    };
  },
  mounted() {
    const searchVal = getStorage("personal-roster");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.loadData();
      this.checkAuthorized();
    })
  },
  computed: {
    ...mapGetters({
      isBelongToCCL: "auth/isBelongToCCL",
      isOCC: "auth/isOCC",
    }),
    columns() {
      const fields = [
        "planDate",
        "day",
        "dws",
        "planStart",
        "planEnd",
        "station",
        "action",
      ];
      const sortable = ["planDate", "day", "dws", "planStart", "planEnd"];
      return {
        fields,
        sortable,
      };
    },
    queryParams() {
      let params = {
        officers: [this.$store.state.auth.userId],
      };
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["dateFrom"] = parseDateTime(this.filters.dateRange.start);
        params["dateTo"] = parseDateTime(this.filters.dateRange.end);
      }
      params["stationId"] = get(
        this.$store,
        "state.auth.station.stationId",
        null
      );
      params["lineId"] = get(this.$store, "state.auth.line.lineId", null);
      return params;
    },
  },
  methods: {
    get,
    isNonActiveShift(selectedRoster) {
      const isOffRest = ["OFF", "REST"].includes(
        get(selectedRoster, "dws", "")
      );
      return isOffRest;
    },
    setDay(day) {
      return moment(toMoment(day)).format("dddd");
    },
    station(station) {
      return get(station, "stationName", "");
    },
    async loadData( status) {
      if(status) setStorage("personal-roster", this.filters);
      this.isLoading = true;
      const resp = await getRosterList({
        skip: this.skip,
        ...this.queryParams,
      });
      if (resp.success) {
        this.tableData = get(resp, "payload.list", []);
        this.error = null;
        this.isLoading = false;
      } else {
        this.error = resp.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
    },
    resetFilters() {
      this.filters.dateRange = null;
    },
    onCancelShiftType(type, selectedRoster) {
      this.selectedRoster = selectedRoster;
      if (type == "BL") {
        this.cancellationShiftType.type = "BL";
        this.cancellationShiftType.message =
          "Are you sure you would like to Cancel Indicated Leave?";
      } else if (type == "OTU") {
        this.cancellationShiftType.type = "OTU";
        this.cancellationShiftType.message =
          "Are you sure you would like to Cancel OT Unavailibility?";
      } else if (type == "OTV") {
        this.cancellationShiftType.type = "OTV";
        this.cancellationShiftType.message =
          "Are you sure you would like to Cancel OT Volunteering?";
      }
      this.showConfirmCancelShiftChange = true;
    },
    async cancelShiftTypeConfirm(type) {
      this.isSubmittingCancelShiftChange = true;
      let result = {};
      if (type == "BL") {
        result = await removeBlockedLeave({
          blockedLeaveId: get(this.selectedRoster, "blockedLeaveId"),
        });
      } else if (type == "OTU") {
        result = await removeOTUnavailability({
          otUnavailableId: get(this.selectedRoster, "otUnavailableId"),
        });
      } else if (type == "OTV") {
        result = await removeOTVolunteer({
          otVolunteerId: get(this.selectedRoster, "otVolunteerId"),
        });
      }
      if (result.success) {
        this.toggleCancelShiftChangeModal(false);
        this.$router.go(this.$router.currentRoute);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.isSubmittingCancelShiftChange = false;
    },
    toggleCancelShiftChangeModal(showModal) {
      this.showConfirmCancelShiftChange = showModal;
    },
    checkAuthorized() {
      if (this.isOCC && !this.isBelongToCCL) {
        router.push({ name: '403 Forbidden' })
      }
    },
  },
};
</script>
